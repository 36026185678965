<template>
  <div>
  
    <v-img class="ma-5" width="200"
      src="https://cdn.aliadosporlasalud.com/MX/Salud-Digna/Home/Header/img/LogoSaludDigna.svg">
    </v-img>

    <v-list>
      <v-list-item v-for=" ruta in routes" link :to="{name:ruta.name}" router exact :key="ruta.id">
        <v-list-item-icon><v-icon>{{ruta.icon}}</v-icon></v-list-item-icon>
        <v-list-item-content><v-list-item-title
            class="text-left">{{ruta.title}}</v-list-item-title></v-list-item-content>
      </v-list-item>
      <v-list-item @click="logOut">
        <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
        <v-list-item-content><v-list-item-title class="text-left">Cerrar
            sesíon</v-list-item-title></v-list-item-content>
      </v-list-item>

    </v-list>
  </div>
</template>
<script>
import {version} from '../../package'
export default {
  name: 'Menu',
    data() {
      return{
        version:version,
        drawer: true,
        // sesion: JSON.parse(sessionStorage.getItem('sesion')),
        ruta: this.$route.path,
        tipo:0,
        usuario: JSON.parse(sessionStorage.getItem('data')),
        routes:[
          {
            name: 'Inicio',
            icon: 'mdi-home',
            title:'Inicio',
            id:1,
            permiso:1
          },
          {
            name: 'hsms',
            icon: 'mdi-message-bulleted',
            title:'HSM',
            id:2,
            permiso:1
          },
          {
            name: 'pendientes',
            icon: 'mdi-send-clock',
            title:'Envios',
            id:3,
            permiso:0
          }, 
          {
            name: 'envios',
            icon: 'mdi-send',
            title:'Envio de templates',
            id:4,
            permiso:0
          }, {
            name: 'generador',
            icon: 'mdi-qrcode',
            title:'Generador de QRs',
            id:5,
            permiso:0
          }, 
           {
            name: 'campanas',
            icon: 'mdi-bullhorn-variant-outline',
            title:'Folios Campañas',
            id:7,
            permiso:0
          }, 
          {
            name: 'documentos',
            icon: 'mdi-cloud-upload-outline',
            title:'Carga de documentos',
            id:9,
            permiso:0
          }, 
          {
            name: 'links',
            icon: 'mdi-link',
            title:'Acortador de Links',
            id:101,
            permiso:0
          }, 
          {
            name: 'correos',
            icon: 'mdi-email',
            title:'Limpieza de correos',
            id:10,
            permiso:0
          }, 
          {
            name: 'blacklists',
            icon: 'mdi-account-cancel',
            title:'Blacklists',
            id:8,
            permiso:0
          }, 
           {
             name: 'Reportes',
             icon: 'mdi-chart-box',
             title:'Reportes',
             id:6,
             permiso:0
           }, 
        ],
        
      }
    },
    components: {
    
    },
    mounted(){
    },
    methods:{
      cambiarTema(){
        //localStorage.setItem('tema', JSON.stringify(!this.tema))
        this.tema=!this.tema
          
      },
      logOut(){
        sessionStorage.removeItem('sesion')
        window.location.href = ''
      }
    }
}
</script>